import classNames from "classnames";
import Link from "next/link";

import { FavoritesTypeEnum, ProductShort } from "@/api/generatedTypes";
import { getFormatedPrice } from "@/helpers/getFormatedPrice";

import { FavoritesHandleButton } from "../FavoritesHandleButton";
import { OnePlusOneLabel } from "../ui-components/OnePlusOneLabel";
import styles from "./ProductItem.module.scss";

interface ProductItemProps {
  product: ProductShort;
  className?: string;
  maxSlides?: number;
}

const ProductItem = ({
  product,
  className = "",
  maxSlides = 2,
}: ProductItemProps) => {
  return (
    <div className={classNames(className, styles.root)}>
      <div className={styles.slides}>
        {product.slides.map((slide, index) => {
          if (index >= maxSlides) return null;
          return (
            <Link
              className={styles.imageContainer}
              href={`/product/${product.slug}`}
              key={slide.id}
            >
              {product.one_plus_one && (
                <OnePlusOneLabel className={styles.onePlusOne} />
              )}
              <img
                src={slide.thumbnail}
                alt={product.alt_name ? product.alt_name : product.name}
              />
            </Link>
          );
        })}
      </div>
      <div className={styles.priceContainer}>
        <div>
          <p className={styles.name}>{product.name}</p>
          <p className={styles.price}>
            {getFormatedPrice(product.price)}
            {!!product.crossed && (
              <span className={styles.oldPrice}>
                {getFormatedPrice(product.old_price)}
              </span>
            )}
          </p>
        </div>
        <FavoritesHandleButton
          itemId={product.id}
          itemType={FavoritesTypeEnum.PRODUCT}
          className={styles.favoritesButton}
        />
      </div>
    </div>
  );
};

export default ProductItem;
