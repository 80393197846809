import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { FavoritesApi } from "@/api/FavoritesApi";
import {
  FavoriteBlock,
  FavoriteProduct,
  FavoritesTypeEnum,
} from "@/api/generatedTypes";
import { useToastHandlers } from "@/components/toaster";
import { ymReachGoalHelper } from "@/components/YandexMetrica";

export const useFavoritesHandlers = () => {
  const favoritesQuery = useQuery(["favorites"], FavoritesApi.get);
  const favorites = favoritesQuery.data?.data || [];
  const favoriteBlocks = favorites.filter(
    (item): item is FavoriteBlock => "block_id" in item
  );
  const favoriteProducts = favorites.filter(
    (item): item is FavoriteProduct => "product_id" in item
  );

  const { addToast } = useToastHandlers();
  const queryClient = useQueryClient();
  const router = useRouter();

  const addFavoriteMutation = useMutation(
    ({
      block_id,
      colored_model_id,
    }: {
      block_id?: string;
      colored_model_id?: string;
    }) => FavoritesApi.add(block_id, colored_model_id),
    {
      onSuccess(data) {
        queryClient.setQueriesData(["favorites"], data);
        const lastItem = data.data[0];
        const isBlock = "block_id" in lastItem;
        const title = isBlock ? lastItem.name || "untitled" : lastItem.name;
        const text = isBlock
          ? "образ добавлен в избранное"
          : "товар добавлен в избранное";

        addToast({
          title,
          text,
          clickHandler: () => router.push("/cart-favorites?index=1"),
        });

        if (isBlock) {
          ymReachGoalHelper("add-block-to-favorites", {
            look_id: lastItem.block_id,
          });
        } else {
          ymReachGoalHelper("add-product-to-favorites", {
            product_slug: lastItem.slug,
          });
        }
      },
      onError() {
        addToast({
          title: "ошибка",
          text: "не удалось добавить товар в избранное",
          variant: "base",
        });
      },
    }
  );

  const deleteFavoritesMutation = useMutation(
    ({ id }: { id: number }) => FavoritesApi.delete(id),
    {
      onSuccess(data) {
        queryClient.setQueriesData(["favorites"], data);
      },
      onError() {
        addToast({
          title: "ошибка",
          text: "не удалось удалить товар из избранного",
          variant: "base",
        });
      },
    }
  );

  const checkIsItemInFavorites = (type: FavoritesTypeEnum, id: string) => {
    switch (type) {
      case FavoritesTypeEnum.BLOCK:
        return favoriteBlocks.some((item) => item.block_id === id);
      case FavoritesTypeEnum.PRODUCT:
        return favoriteProducts.some((item) => item.product_id === id);

      default:
        return false;
    }
  };

  const addBlockToFavorites = (id: string) => () => {
    addFavoriteMutation.mutate({ block_id: id });
  };

  const addProductToFavorites = (id: string) => () => {
    addFavoriteMutation.mutate({ colored_model_id: id });
  };

  const deleteFromFavorites = (id: number) => {
    deleteFavoritesMutation.mutate({ id });
  };

  const deleteProductOrBlockFromFavorites =
    (type: FavoritesTypeEnum, id: string) => () => {
      switch (type) {
        case FavoritesTypeEnum.BLOCK: {
          const itemInFavorites = favoriteBlocks.find(
            (item) => item.block_id === id
          );
          itemInFavorites &&
            deleteFavoritesMutation.mutate({ id: itemInFavorites.id });
          return;
        }

        case FavoritesTypeEnum.PRODUCT: {
          const itemInFavorites = favoriteProducts.find(
            (item) => item.product_id === id
          );
          itemInFavorites &&
            deleteFavoritesMutation.mutate({
              id: itemInFavorites.id,
            });
          return;
        }

        default:
          return;
      }
    };

  const isMutationInProgress =
    deleteFavoritesMutation.isLoading || addFavoriteMutation.isLoading;

  return {
    checkIsItemInFavorites,
    addBlockToFavorites,
    addProductToFavorites,
    deleteFromFavorites,
    deleteProductOrBlockFromFavorites,
    isMutationInProgress,
  };
};
