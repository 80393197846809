export const getFormatedPrice = (price: string | number | undefined) => {
  if (typeof price === "string") {
    const numbered = Number(price);
    if (isNaN(numbered)) return "";
    return Number(price).toLocaleString("ru-RU") + " RUB";
  }
  if (typeof price === "number") {
    return price.toLocaleString("ru-RU") + " RUB";
  }
  return "";
};
