import axios from "axios";

import { Product, ProductShort } from "@/api/generatedTypes";

interface SimilarRequest {
  slug: string;
  limit?: number;
  offset: number;
}

export const PRODUCTS_PER_PAGE = 24;

const ProductApi = {
  all: ({ filter = {}, limit = PRODUCTS_PER_PAGE, offset = 0 }) =>
    axios.post<ProductShort[]>("/products", {
      limit,
      offset,
      ...filter,
    }),

  find: (slug: string) => {
    return axios.get<Product>(`products/${slug}`);
  },
  similar: ({ slug, limit = PRODUCTS_PER_PAGE, offset }: SimilarRequest) => {
    return axios.get<ProductShort[]>(`products/${slug}/similar`, {
      params: { limit, offset },
    });
  },
};

export default ProductApi;
