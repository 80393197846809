/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Роль пользователя
 */
export enum RoleEnum {
  GUEST = "guest",
  USER = "user",
  EMPLOYEE = "employee",
  ADMIN = "admin",
}
