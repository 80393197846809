import { MutableRefObject, useEffect } from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";

import { BLOCKS_PER_PAGE } from "@/config";

import { goUpFunctionsState } from "./goUpFunctionsState";
import { goUpState } from "./goUpState";

interface useGoUpListenerProps {
  key: "blocks" | "products";
  contentTabRef: MutableRefObject<HTMLDivElement | null>;
  reset(): void;
}

export const useGoUpListener = ({
  key,
  contentTabRef,
  reset,
}: useGoUpListenerProps) => {
  const setGoUpState = useSetRecoilState(goUpState);
  const setGoUpFunctions = useSetRecoilState(goUpFunctionsState);
  const resetGoUpState = useResetRecoilState(goUpState);

  const onScroll = (event: Event) => {
    const rowGap = 8;
    const target = event.target as HTMLDivElement | null;
    if (!target) return;

    const childElementHeight = target.children[0].clientHeight;
    const isFourthPage =
      (BLOCKS_PER_PAGE / 2) * (childElementHeight + rowGap) < target.scrollTop;

    setGoUpState((state) => {
      if (state[key] === isFourthPage) return state;
      return { ...state, [key]: isFourthPage };
    });
  };

  useEffect(() => {
    if (!contentTabRef.current) return;
    setGoUpFunctions((state) => ({ ...state, [key]: reset }));
    contentTabRef.current.addEventListener("scroll", onScroll);

    return () => {
      contentTabRef.current?.removeEventListener("scroll", onScroll);
      resetGoUpState();
    };
  }, [contentTabRef.current]);
};
