import { CollectionBanner as CollectionBannerType } from "@/api/generatedTypes";
import { Banner } from "@/components/Banner";
import { useFilterHandlers } from "@/components/filter/useFilterHandlers";

import styles from "./CollectionBanner.module.scss";

interface CollectionBannerProps {
  banner: CollectionBannerType;
}

const CollectionBanner = ({ banner }: CollectionBannerProps) => {
  const { applyCollections } = useFilterHandlers();

  const onClick = () => {
    applyCollections([banner.collection_id]);
  };

  return (
    <Banner
      src={banner.banner}
      onClick={onClick}
      alt={banner.name}
      poster={banner.banner_poster}
      className={styles.banner}
    />
  );
};

export default CollectionBanner;
