/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Статус заказа
 */
export enum OrderStatusEnum {
  Оформлен = "Оформлен",
  Сборка = "Сборка",
  "В пути" = "В пути",
  Завершен = "Завершен",
  Отменен = "Отменен",
}
