import { LayoutBase } from "../LayoutBase";
import styles from "./LayoutProducts.module.scss";

interface LayoutProductsProps {
  children: React.ReactNode;
  title?: string;
  stickyContainerSlot: React.ReactNode;
}

const LayoutProducts = ({
  children,
  title,
  stickyContainerSlot,
}: LayoutProductsProps) => {
  return (
    <LayoutBase className={styles.root} title={title}>
      <div className={styles.stickyContainer}>{stickyContainerSlot}</div>
      {children}
    </LayoutBase>
  );
};

export default LayoutProducts;
