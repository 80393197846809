import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";

import { divScrollState } from "./divScrollState";

export const useDivScrollRestore = (containerName: string) => {
  const [scrollPositions, setScrollPositions] = useRecoilState(divScrollState);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  useEffect(() => {
    router.events.on("routeChangeStart", handleRouteChange);

    /*
     * Почему-то не работает без задержки таймера.
     * Хотя на момент вызова функции у контейнера уже есть актуальный scrollHeight.
     * Чтобы не прыгало, можно переделать на scrollIntoView. То есть запоминать элемент,
     * на который кликнули, и потом возвращать на него. Но если клик был на меню или ещё что,
     * то такое не сработает.
     */
    if (scrollPositions[containerName]) {
      setTimeout(() => {
        containerRef.current?.scrollTo(0, scrollPositions[containerName] || 0);
      }, 1);
    }

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  const handleRouteChange = () => {
    setScrollPositions((state) => ({
      ...state,
      [containerName]: Number(containerRef.current?.scrollTop),
    }));
  };

  const reset = () => {
    setScrollPositions((state) => ({
      ...state,
      [containerName]: 0,
    }));
    containerRef.current?.scrollTo(0, 0);
  };

  return { containerRef, reset };
};
