import { MutableRefObject, useEffect, useState } from "react";

import { BLOCKS_PER_PAGE } from "@/config";

export const useProductGoUpListener = (
  containerRef: MutableRefObject<HTMLDivElement | null>
) => {
  const [isArrowShown, setIsArrowShown] = useState(false);
  const onScroll = () => {
    const containerFromTop =
      containerRef.current?.getBoundingClientRect().top || 0;
    const rowGap = 8;
    const target = containerRef.current as HTMLDivElement | null;
    if (!target) return;

    const childElementHeight = target?.children?.[0]?.clientHeight || 0;
    const isFourthPage =
      containerFromTop + (BLOCKS_PER_PAGE / 2) * (childElementHeight + rowGap) <
      target.scrollTop;

    setIsArrowShown(isFourthPage);
  };

  useEffect(() => {
    if (!containerRef.current) return;
    window.addEventListener("scroll", onScroll);

    return () => {
      window?.removeEventListener("scroll", onScroll);
    };
  }, [containerRef.current]);

  return isArrowShown;
};
