import classNames from "classnames";
import Link from "next/link";
import React, { MouseEvent } from "react";

import styles from "./Banner.module.scss";

interface BannerProps {
  src: string;
  className?: string;
  link?: string;
  onClick?(): void;
  alt?: string;
  poster?: string;
}

const Banner = ({
  src,
  className = "",
  alt,
  link,
  onClick,
  poster,
}: BannerProps) => {
  const isVideo = src.split(".").pop()?.toLowerCase() === "mp4";
  const onClickHandler = getOnClickHandler(onClick, link);

  return (
    <Link
      className={classNames(styles.banner, className)}
      href={link || "#"}
      onClick={onClickHandler}
    >
      {isVideo ? (
        <video
          muted={true}
          autoPlay={true}
          loop={true}
          playsInline={true}
          preload="auto"
          src={src}
          poster={poster}
        />
      ) : (
        <img src={src} alt={alt || "SALE"} />
      )}
    </Link>
  );
};

type onClickArg = () => void;

const getOnClickHandler = (
  onClick: undefined | onClickArg,
  link: string | undefined
) => {
  if (!onClick && !link) {
    return (e: MouseEvent) => {
      e.preventDefault();
    };
  }

  if (onClick) {
    return (e: MouseEvent) => {
      e.preventDefault();
      onClick();
    };
  }

  return undefined;
};

export default Banner;
