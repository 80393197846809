import styles from "./BlockProductsEmpty.module.scss";

interface BlockProductsEmptyProps {
  isProductEmpty: boolean;
  isBlocksEmpty: boolean;
}

const BlockProductsEmpty = ({
  isProductEmpty,
  isBlocksEmpty,
}: BlockProductsEmptyProps) => {
  return (
    <div className={styles.root}>
      {isProductEmpty && isBlocksEmpty && (
        <p>
          по вашему запросу ничего <br /> не найдено
        </p>
      )}
      {!isProductEmpty && isBlocksEmpty && (
        <p>
          найдены только товары, образов с <br />
          такими критериями нет
        </p>
      )}
      {isProductEmpty && !isBlocksEmpty && (
        <p>
          найдены только образы, товаров с <br />
          такими критериями нет
        </p>
      )}
    </div>
  );
};

export default BlockProductsEmpty;
