import classNames from "classnames";

import { FavoritesTypeEnum } from "@/api/generatedTypes";
import { useFavoritesHandlers } from "@/hooks/useFavoritesHandlers";

import { FavoritesIcon, FavoritesIconFilled } from "../icons";
import { MainButton } from "../ui-components/MainButton";
import styles from "./FavoritesHandleButton.module.scss";

interface FavoritesHandleButtonProps {
  className?: string;
  itemId: string;
  itemType: FavoritesTypeEnum;
}

const FavoritesHandleButton = ({
  className = "",
  itemId,
  itemType,
}: FavoritesHandleButtonProps) => {
  const {
    checkIsItemInFavorites,
    addProductToFavorites,
    addBlockToFavorites,
    deleteProductOrBlockFromFavorites,
    isMutationInProgress,
  } = useFavoritesHandlers();

  const isInFavorites = checkIsItemInFavorites(itemType, itemId);

  const addToFavoritesHandler =
    itemType === FavoritesTypeEnum.BLOCK
      ? addBlockToFavorites
      : addProductToFavorites;

  return (
    <MainButton
      className={classNames(styles.root, className)}
      disabled={isMutationInProgress}
      onClick={
        isInFavorites
          ? deleteProductOrBlockFromFavorites(itemType, itemId)
          : addToFavoritesHandler(itemId)
      }
    >
      {isInFavorites ? <FavoritesIconFilled /> : <FavoritesIcon />}
    </MainButton>
  );
};

export default FavoritesHandleButton;
